@import "../../styles/var";

.empty-results {
  padding: 24px 16px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $sourCream;
  border: 1px solid $haddock;
  border-radius: 8px;
  box-sizing: border-box;

  @media screen and (min-width: $tablet) {
    height: calc(100vh - 80px - 40px - 48px - 32px);
    margin: 0 16px !important;
    margin-left: 0 !important;
  }

  &--no-results {
    @media screen and (min-width: $tablet) {
      height: calc(100vh - 80px - 24px - 32px);
      margin-left: 0;
    }
  }

  &__icon {
    width: 81.25px;

    @media screen and (min-width: $tablet) {
      width: 140px;
    }
  }

  &__line-1 {
    margin: 16px 0 4px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20.25px;

    @media screen and (min-width: $tablet) {
      margin: 24px 0 8px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__line-2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.9px;
    text-align: center;

    @media screen and (min-width: $tablet) {
      font-size: 15px;
      line-height: 20.25px;
    }
  }
}