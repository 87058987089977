@import "../../styles/var";

.dashboard {
  position: absolute;
  background-color: $feta;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  @media screen and (min-width: $tablet) {
    left: 110px;
  }

  &__inner {
    padding: 16px 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: $tablet) {
      padding: 24px 16px 32px 0;
    }
  }

  &__section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: 1000px) {
      flex-direction: row-reverse;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;

    @media screen and (min-width: $tablet) {
      text-align: initial;
      align-items: initial;
    }
  }

  &__greeting {
    display: none;
    color: $soySauce;
    font-weight: 700;
    font-size: 26px;
    line-height: 33.8px;

    @media screen and (min-width: $tablet) {
      display: flex;
      gap: 8px;
    }
  }

  &__user-icon {
    width: 32px;
  }

  &__region {
    font-weight: 700;
    font-size: 18px;
    line-height: 24.3px;
    letter-spacing: 0.00em;

    @media screen and (min-width: $tablet) {
      font-weight: 600;
    }
  }

  &__region-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.6px;
    letter-spacing: -0.05em;
  }

  &__summary-tile-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;

    @media screen and (min-width: $tablet) {
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }
  }

  &__postcode-tag-wrapper {
    display: flex;
    gap: 8px;
    overflow: hidden;
  }

  &__more-tag {
    height: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $truffle;
    border-radius: 4px;
    color: $sourCream;
    font-weight: 500;
    font-size: 13px;
    line-height: 18.2px;
    letter-spacing: 0.01em;
  }

  &__button {
    height: 40px;
    width: max-content;
    margin: 0 0 24px 24px;
    padding: 0 12px;
    position: sticky;
    top: 56px;
    z-index: 2;
    display: flex;
    gap: 4px;
    align-items: center;
    border: 1px solid $eel;
    border-radius: 4px;
    background-color: $sourCream;
    cursor: pointer;
    box-sizing: border-box;

    font-weight: 600;
    font-size: 15px;
    color: $truffle;

    &:hover {
      border-color: $thali01;
    }

    @media screen and (min-width: $tablet) {
      margin: 0 0 32px 32px;
      top: 24px;
    }

    &--open {
      padding: 0 11px;
      border: 2px solid $thali01;
    }
  }

  &__icon-open {
    transform: rotate(180deg);
  }
}

.dashboard-panel {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid $eel;
  background-color: $sourCream;

  &--productivity {
    cursor: pointer;
  }

  &--orders {
    flex: 1;
  }

  &__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: $tablet) {
      padding: 32px;
    }

    &--summary {
      @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 0;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &--no-subtitle {
      margin-bottom: 8px;
      align-items: center;
    }
  }

  &__title {
    color: $soySauce;
    font-weight: 700;
    font-size: 18px;
    line-height: 24.3px;

    @media screen and (min-width: $tablet) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__subtitle {
    margin-top: 4px;
    color: $truffle;
    font-weight: 500;
    font-size: 15px;
    line-height: 20.25px;
    
    @media screen and (min-width: $tablet) {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.05em;
    }
  }

  &__icon-wrapper {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $soySauce;
    border-radius: 100%;
  }

  &__tag-wrapper {
    display: flex;
    gap: 8px;
  }

  &__lever {
    display: flex;
    flex-direction: column;
    gap: 4px;

    color: #464141CC;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.9px;
  }

  &__lever-wrapper {
    display: grid;
    grid-template-columns: auto;
    gap: 8px 24px;

    @media screen and (min-width: $tablet) {
      grid-template-columns: repeat(2, auto);
    }
  }

  &__orders-tile-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    flex: 1;

    & > :nth-child(5) {
      grid-column: span 2;
    }

    @media screen and (min-width: $tablet) {
      grid-template-columns: repeat(6, 1fr);
      gap: 16px;

      & > :nth-child(-n + 3) {
        grid-column: span 2;
      }
        
      & > :nth-child(n + 4) {
        grid-column: span 3;
      }
    }
  }
}

.dashboard-table {
  zoom: 100%; // remove
  border-spacing: 0px;

  &__wrapper {
    padding: 0 24px 24px;
    overflow: auto;
    height: 500px;

    @media screen and (min-width: $tablet) {
      padding: 0 32px 32px;
    }
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $sourCream;
  }

  &__row {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      height: 1px;
      left: 0;
      right: 0;
      bottom: 16px;
      border-bottom: 1px dashed $eel;
    }

    &:last-child {
      td {
        padding-bottom: 0;
      }

      &::after {
        display: none;
      }
    }
  }

  &__cell {
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $eel;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $sourCream;
    text-align: center;

    font-size: 15px;
    font-weight: 500;
    line-height: 20.25px;
    color: $soySauce;

    .dashboard-table__row:hover & {
      border-color: $thali01;
    }

    &--header {
      min-width: 124px;
      flex-direction: column;
      gap: 4px;
      background-color: $garlic;
      font-weight: 600;
      color: $truffle;
    }

    &--header-small {
      min-width: 80px;
    }

    &--double-height {
      height: 120px;
    }

    &--warning {
      background-color: $bubblegum;
      border-color: $salmon;
      color: $fmo;

      .dashboard-table__row:hover & {
        border-color: $fmo;
      }
    }

    &--success {
      background-color: $courgette;
      border-color: $kiwi;

      .dashboard-table__row:hover & {
        border-color: $artichoke;
      }
    }

    &--split {
      padding: 0 16px;
      justify-content: space-between;
    }

    &--orders {
      background-color: $fmo;
      color: $sourCream;
      border: 0;
      white-space: nowrap;
    }

    &--levers {
      background-color: $cremeBrulee;
      border: 0;
    }

    &--sms {
      background-color: $plum;
      color: $sourCream;
      border: 0;
      white-space: nowrap;
    }

    &--numbers {
      background-color: $lavender;
      border: 0;
      white-space: nowrap;
    }
  }

  &__cell-stack {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

td, th {
  padding: 0 8px 32px 0;

  &:last-child {
    padding-right: 0;
  }
}

th {
  padding-bottom: 8px;
}