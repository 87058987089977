@import "../../../styles/var";

.user-ribbon {
  height: 40px;
  padding: 0 16px;
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: $fmo;

  @media screen and (min-width: $tablet) {
    display: none;
  }

  &--overview {
    display: flex;

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  &--checked-in {
    background-color: $soySauce;
  }

  &__user-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    box-sizing: border-box;
  
    font-size: 15px;
    font-weight: 600;
    line-height: 20.25px;
    color: $sourCream;
  }

  &__light-text {
    font-weight: 500;
  }
}