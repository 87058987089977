@import "styles/table";
@import "styles/var";

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media print {
    * {
        font-family: Arial, Helvetica, sans-serif;
    }
}

* {
    font-family: "Inter", sans-serif;

    &:focus {
        outline: none;
    }

    // -webkit-tap-highlight-color: transparent;

    font-size: 100%;

    ::placeholder {
        font-weight: 400 !important;
    }
}

.no-scroll {
    overflow: hidden !important;
    -webkit-overflow-scrolling: hidden !important;
}

.jitter-fix {
    right: 12px !important;
}

.react-datepicker-popper {
    z-index: 999999;
}
button {
    cursor: pointer;
    height: 46px;
    border-radius: 5px;
    letter-spacing: 0.01em;
    font-size: 15px;
    font-weight: 600;
}

body {
    background: #fcfcfc;

    @media print {
      background: none;
    }
}

input {
    -webkit-appearance: none;
}
.blank-menu {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;

    background: $feta;
    border-radius: 5px;
}

.nav-icon {
    width: 46px;
    height: 46px;
}

.nav-icon-top {
    width: 46px;
    height: 46px;
    cursor: pointer;
}

.nav-icon-top:first-child {
    margin-right: 8px;
}

.nav-icon-top img {
    width: 24px;
    height: 24px;
}

.nav-user-icon {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
}

.nav-filter-input {
    display: flex;
    align-items: center;
}


.nav-input {
    height: 46px;
    min-width: 360px;
    max-width: 360px;
    width: 300px;
    padding-left: 16px;
    border: 1px solid #EDEBEB;
    border-radius: 5px;
    color: #302A2A;
    font-weight: 500;
    font-size: 15px;
    box-sizing: border-box;

    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;

    &::placeholder {
        font-weight: 400;
    }

    &:focus {
        border: 1px solid #FFDEDB;
        // min-width: calc(100vw - 380px) !important;
        // -webkit-transition: 0.2s ease-out;
        // -moz-transition: 0.2s ease-out;
        // -o-transition: 0.2s ease-out;
        // transition: 0.2s ease-out;
    }
}

.nav-search-button {
    background: $pink;
    border: none;
    border-radius: 5px;
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -46px;
}

.nav-search-button img {
    padding-bottom: 1px;
}

.side-bar {
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 0;
    width: 78px;
    min-width: 78px;
    max-width: 78px;
    background: $feta;
    z-index: 101;
    padding: 24px 16px 32px 16px;
}

.side-bar-inner {
    background: $sourCream;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 24px 0 32px 0;
    padding: 16px 0;
    border-radius: 5px;
    width: 78px;
    max-width: 78px;
    min-width: 78px;
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: $sourCream;
    border: 1px solid $haddock;
    gap: 16px;
    
    cursor: pointer;

}


.nav-bar {
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    width: 100%;
    min-width: 100vw;
    max-width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    resize: horizontal;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    background: $sourCream;
    z-index: 200;
    border-bottom: 1px solid $haddock;
    padding-left: 32px;
    padding-right: 32px;
    inset: 0;
    box-sizing: border-box;
}

.nav-logo {
    width: auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.nav-box-left {
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-left: 32px;
    gap: 32px
}

.nav-filter-box {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 8px;
}

.nav-box-right {
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    display: flex;
    align-items: center;
}

.nav-brand-name {
    padding-right: 50px;
    display: inline;
    @media screen and (max-width: 1280px) {
        display: none;
    }
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 200px;
}

.menu-icon {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 46px;
    min-height: 46px;
    cursor: pointer;
}

.menu-icon-inner img {
    width: 46px;
    height: 46px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: transparent;
}

.nav-name {
    font-weight: 500;
    letter-spacing: 0.005em;
    color: $truffle;
    text-transform: capitalize;
    font-size: 15px;
    text-align: right;
}

.nav-surname {
    font-weight: 500;
}

// Generic page styles

.title {
    font-weight: 800;
    font-size: 50px;
    color: $soySauce;
    padding-left: 4px;
    padding-bottom: 4px;
    height: 50px;
    line-height: 50px;
}

.spinner-border {
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 70vh;
}




.label {
    display: block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.01em;
    margin: 16px 0;
    padding-left: 4px;
    color: $truffle !important;
}

// * {
//     scrollbar-color: white #e5e5e5;
// }

// body::-webkit-scrollbar {
//     width: 12px; /* width of the entire scrollbar */
//     height: 12px;
// }

// body::-webkit-scrollbar-track {
//     background: white; /* color of the tracking area */
// }

// body::-webkit-scrollbar-thumb {
//     background-color: #e5e5e5; /* color of the scroll thumb */
//     border-radius: 20px; /* roundness of the scroll thumb */
//     border: 3px solid white; /* creates padding around scroll thumb */
// }

.modalTitle {
    font-weight: 800;
    font-size: 26px;
    line-height: 120%;
    color: $soySauce;
    letter-spacing: 1%;
}

.allFilters {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.filterSection {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--first {
        gap: 48px;
    }
}


.filterSection:nth-child(2) {
    .badge {
        opacity: 0;
    }
}

.upArrow {
    margin-left: -4px;
}

.filterSection:nth-child(6) {
    margin-right: 32px;
}

.badge {
    background-color: $sorbet;
    height: 20px;
    padding: 0 8px;
    border-radius: 5px;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    color: $fmoRed;
    text-transform: capitalize;
    letter-spacing: .02em;
    line-height: 20px;

    &--show {
        opacity: 1 !important;
    }

    &--hide {
        opacity: 0 !important;
    }
}

.badgeNeutral {
    background-color: $garlic !important;
    color: $thali02 !important;
    border: 1px solid $haddock !important;
}

.badgeDark {
    background-color: $soySauce !important;
    color: $sourCream !important;
    font-weight: 600 !important;
    font-size: 10px !important;
    letter-spacing: 0.02em !important;
}

.badgePink {
    background-color: $sorbet !important;
    color: $fmoRed !important;
    font-weight: 600 !important;
    font-size: 10px !important;
    letter-spacing: .03em !important;
}

.modalDivider {
    background-color: $haddock;
    width: 1px;
    margin: 16px 0;
}

.modalClear {
    background: $haddock;
    border-radius: 5px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    font-weight: 600;
    color: $truffle;
    font-size: 15px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    flex-grow: unset !important;

    cursor: pointer;
}

.reloadResults {
    background: $fmoRed;
    border-radius: 5px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    font-weight: 600;
    color: $sourCream;
    font-size: 15px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    flex-grow: unset !important;
    cursor: pointer;
}

.modalBottomRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 32px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modalClose {
    cursor: pointer;
}

.ReactModal__Overlay--after-open {
    z-index: 500;
}

.ReactModal__Content--after-open {
    box-shadow: 4px 4px 50px rgba(47, 38, 33, 0.05);
    // display: unset !important;
}

.modalBtn {
    width: 100%;
    background: $pink;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    color: $fmoRed;
    height: 40px;
    box-shadow: none;
    border: none;
    margin-top: 50px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.modalTxt {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    text-align: left;
}

.modalTopRow {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;

    .closeBtn {
        position: inherit;
    }
}

.modalRow {
    display: flex;
    align-items: center;
    align-content: center;

    label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 2px;

        color: $truffle;
    }

    input[type="radio"] {
        display: none;
    }

    input[type="radio"] + label:before {
        content: "";
        //background-image: url();
        display: inline-block;
        border-radius: 100px;
        width: 20px;
        height: 20px;
        background: $garlic;
        color: $garlic;
        border: 1px solid $thali01;
        line-height: 11px;
        text-align: center;
        font-size: 25px;
        margin-right: 10px;
    }

    input[type="radio"]:checked + label:before {
        background: $sorbet;
        border-color: $fmoRed;
    }


    input[type="radio"]:checked + label:after {
        content: "";
        margin-left: 9px;
        margin-bottom: 2px;
        height: 7px;
        width: 3px;
        display: inline-block;
        border-top: 1px solid $fmoRed;
        border-left: 1px solid $fmoRed;
        transform: rotate(-135deg) !important;
        -webkit-tranform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        position: absolute;
    }
}

.react-toggle {
    vertical-align: middle;
    margin-right: 8px;

    .react-toggle-track {
        background: $haddock !important;
        border: 1px solid $thali01;
        height: 20px;
        width: 40px !important;
    }

    .react-toggle-thumb {
        background: $garlic;
        border: 1px solid $thali01;
        top: 0;
        left: 0;
        //height: px;
        width: 22px;
        box-shadow: none !important;
    }
}

.react-toggle--checked {
    vertical-align: middle;
    .react-toggle-track {
        background: $bubblegum !important;
        border: 1px solid $fmoRed;
        height: 20px;
        width: 40px !important;
    }

    .react-toggle-thumb {
        background: $sorbet;
        border: 1px solid $fmoRed;
        left: 20px;
        top: 0;
        height: 22px;
        width: 22px;
        box-shadow: none !important;
    }
}

label span {
    display: inline-flex;
}

.show-only {
    display: flex;
    justify-content: center;
    align-self: center;

    :first-child {
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .box {
        min-width: 35px;
        min-height: 35px;
        margin-left: 8px;
        border-radius: 10px;

        &:last-of-type {
            margin-right: 8px;
        }
    }
}

.icon-off {
    opacity: 0.7 !important;
}

.no-results {
    margin-top: 16px;
    margin-left: 8px;

    div {
        margin-top: 32px;

        font-style: normal;
        font-weight: 450;
        font-size: 15px;
        line-height: 20px;

        color: $thali02;
    }
}

.CCOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    overflow: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    .CCModal {
        position: absolute;
        background: white;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        border: none;
        padding: 35px;
        max-width: 400px;

        .modalLocation {
            display: flex;
            align-items: center;

            img {
                margin-right: 20px;
                width: 44px;
                height: 44px;
            }

            :nth-child(2) {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #252525;
            }

            :last-child {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: right;
                letter-spacing: 0.05em;
                text-transform: capitalize;
                flex-grow: 1;

                color: #424242;
            }
        }

        .modalCard {
            background: #fcfcfc;
            color: #424242;
            padding: 20px;
            text-align: center;
            border-radius: 10px;
            margin-top: 20px;

            .modalCardName {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
            }

            .modalCardContent,
            .modalCardPhone {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }

            .modalCardPhone {
                margin-top: 4px;
            }
        }
    }

    .LogModal {
        top: 0;
    }

    .HistoryModal {
        position: absolute;
        background: white;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        border: none;
        padding: 35px;
        width: 400px;
    }

    .LogModal,
    .JobModal {
        position: absolute;
        background: white;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        border: none;
        padding: 35px;
        width: 400px;

        .purple {
            background: $purple !important;
        }

        .shift-up {
            margin-top: -20px;
        }

        .center {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .modal-subtitle {
            margin-top: 20px;

            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            text-transform: capitalize;

            color: #252525;
            margin-bottom: 10px;
        }

        .purple-active {
            background: $lavender !important;
            color: $purple !important;
        }

        .log-category {
            background: #fcfcfc;
            padding: 0px 16px;
            height: 48px;
            color: $text;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;

            display: flex;
            align-items: center;
            margin-bottom: 10px;
            border-radius: 10px;
            cursor: pointer;
        }

        .log-reason {
            cursor: pointer;
            background: white;
            padding: 0px 24px;
            height: 48px;
            color: $text;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;

            align-items: center;
            border: 1px solid #fcd7d4;
            box-sizing: border-box;
            border-radius: 10px;
            margin-bottom: 10px;
            justify-content: center;
        }

        .log-active {
            background: $pink;
            color: $fmoRed;
        }

        .log-input {
            display: flex;
            flex-direction: column;
            div {
                margin-top: 20px;

                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;
                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #252525;
                margin-bottom: 10px;
            }
            textarea {
                border: none;
                background: #fcfcfc;
                border-radius: 10px;

                font-size: 15px;
                font-weight: 450;
                line-height: 17px;
                letter-spacing: 0.05em;
                padding: 24px;
                min-height: 150px;

                &::placeholder {
                    color: #acabab;
                }
            }

            button {
                background: #d82b31;
                border-radius: 10px;
                color: white;
                font-size: 14px;
                line-height: 15px;
                box-shadow: none;
                height: 48px;
                border: none;
                margin-top: 10px;
            }
        }
    }
}

.whiteBackground {
    background: white;
}

.borderRadius10 {
    border-radius: 10px;
}

@media (max-width: 768px) {
    .no-results {
        margin-top: 60px !important;
        margin-left: 4px !important;
        position: fixed;

        div {
            margin-top: 16px;
        }
    }

    .jitter-fix {
        right: 0 !important;
    }
}


.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
