@import "../../styles/var";

.filter-tabs {
  display: flex;
  border-bottom: 1px solid $eel;

  &--header {
    width: 100%;
    display: flex;
    position: fixed;
    top: 80px;
    background-color: $sourCream;
  
    @media screen and (min-width: $tablet) {
      width: auto;
      position: static;
      border: 1px solid $eel;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  &__tab {
    height: 48px;
    display: flex;
    flex: 1;
    gap: 4px;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    border-left: 1px solid $eel;

    color: $thali02;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.01em;

    @media screen and (min-width: $tablet) {
      padding: 0 8px;
    }

    &:first-child {
      border-left: none;
    }

    &--header {
      @media screen and (min-width: $tablet) {
        height: 40px;
      }
    }

    &--disabled {
      cursor: initial;
    }

    &--active {
      color: $darkText;

      &::after {
        content: '';
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $kiwi;
      }
    }
  }
}