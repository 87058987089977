@import "../../styles/var";

$tableGutter: 8px;

.agent-shop-list {
  position: absolute;
  background-color: $feta;
  top: 80px;
  top: 104px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  @media screen and (min-width: $tablet) {
    padding: 0;
    left: 110px;
    top: 104px;
  }

  &__search-wrapper {
    width: 100%;
    height: 56px;
    padding: 0 16px 16px;
    position: fixed;
    top: 72px;
    z-index: 400;
    background-color: $sourCream;
    border-bottom: 1px solid $eel;
    box-sizing: border-box;
  }

  &__filter-button-wrapper {
    width: 100%;
    padding: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    position: fixed;
    background-color: $feta;
    box-sizing: border-box;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: $tablet) {
      width: auto;
      margin: 0;
      padding: 24px 16px 24px 0;
      left: 110px;
      right: 0;
    }
  }

  &__title {
    margin: 0 16px;
    padding: 0 0 0 4px;
    position: sticky;
    left: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: $soySauce;

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }
}

.shop-list-table {
  width: 100%;
  padding: 0 16px;
  zoom: 100%; // remove
  display: table;
  border-spacing: 0px;

  @media screen and (min-width: $tablet) {
    padding: 0 16px 24px 0;
  }

  &__wrapper {
    padding: 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: $tablet) {
      padding: 0;
    }
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: $feta;
  }

  &__cell-wrapper {
    &--favourite, &--rank {
      min-width: 56px;
      width: 56px;
    }

    &--wholesaler_id, &--device {
      min-width: 80px;
      width: 80px;
    }

    &--caller_id {
      min-width: 104px;
      width: 104px;
    }
  }

  &__cell {
    height: 56px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $eel;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $sourCream;

    font-size: 15px;
    font-weight: 500;
    line-height: 20.25px;
    color: $soySauce;

    .shop-list-table__row:hover & {
      border-color: $thali01;
    }

    &--header {
      min-width: 124px;
      flex-direction: column;
      gap: 4px;
      background-color: $garlic;
      font-weight: 600;
      cursor: pointer;
      color: $truffle;
    }

    &--combined {
      min-width: 160px;
      max-width: 160px;
      padding: 0 16px;
      gap: 40px;
      flex-direction: row;
      white-space: nowrap;
      justify-content: space-between;

      @media screen and (min-width: $tablet) {
        min-width: 360px;
        max-width: 360px;
      }
    }

    &--favourite, &--rank, &--wholesaler_id, &--device, &--caller_id {
      min-width: initial;
    }

    &--expandable {
      align-items: flex-start;
    }

    &--orders_epos {
      background-color: $truffle;
      color: $sourCream;
      border: 0;
    }

    &--orders {
      background-color: $fmo;
      color: $sourCream;
      border: 0;
    }

    &--info {
      background-color: $blueberry;
      color: $sourCream;
      border: 0;
    }

    &--numbers {
      background-color: $lavender;
      border: 0;
    }

    &--sms {
      background-color: $plum;
      color: $sourCream;
      border: 0;
    }

    &--marketing {
      background-color: $cremeBrulee;
      border: 0;
    }

    &--success {
      background-color: $courgette;
      border-color: $kiwi;

      .shop-list-table__row:hover & {
        border-color: $artichoke;
      }
    }

    &--success-dark {
      background-color: $rocket;
      border: 2px solid $kiwi;

      .shop-list-table__row:hover & {
        border-color: $artichoke;
      }
    }

    &--warning {
      background-color: $bubblegum;
      border-color: $salmon;
      color: $fmo;

      .shop-list-table__row:hover & {
        border-color: $fmo;
      }
    }

    &--warning-dark {
      background-color: $sorbet;
      border: 2px solid $salmon;
      color: $pepperoni;

      .shop-list-table__row:hover & {
        border-color: $fmo;
      }
    }

    &--disabled {
      color: $thali01;
      border-color: $haddock;

      .shop-list-table__row:hover & {
        border-color: $eel;
      }
    }

    &--split {
      padding: 0 16px;
      justify-content: space-between;
    }

    td & { // this is to make 'to activate' tag wrap
      white-space: nowrap;
    }
  }

  &__toggle-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &--expandable {
      justify-content: space-between;
    }
  }

  &__tag-wrapper {
    display: flex;
    gap: 4px;
  }

  &__caret-wrapper {
    margin-left: 16px;
    position: relative;
    display: flex;

    &:after {
      content: '';
      width: 32px;
      height: 32px;
      position: absolute;
      top: -8px;
      left: -8px;
    }
  }

  &__caret-icon {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);

    &--open {
      transform: rotate(0deg);
    }
  }

  &__row {
    cursor: pointer;

    .shop-list-table__cell {
      background-color: $sourCream;
    }

    &--0 td, &--2 td, &--5 td, &--6 td, &--7 td, &--8 td {
      background-size: 100% 56px;
      background-repeat: no-repeat;

      .shop-list-table__cell {
        background-color: initial;

        &--warning {
          background-color: #FFF3F280;
        }

        &--warning-dark {
          background-color: #FDE2E280;
        }

        &--success {
          background-color: #F1F8ED80;
        }

        &--success-dark {
          background-color: #DBECCF80;
        }
      }
    }

    &--0 td, &--5 td, &--7 td {
      background-image: repeating-linear-gradient(
        -45deg,
        $feta,
        $feta 16px,
        #D82B3114 16px,
        #D82B3114 32px
      );
    }

    &--6 td, &--8 td {
      background-image: repeating-linear-gradient(
        -45deg,
        $feta,
        $feta 16px,
        #FFA82929 16px,
        #FFA82929 32px
      );
    }

    &--2 td {
      background-image: repeating-linear-gradient(
        -45deg,
        $feta,
        $feta 16px,
        #3D6DE814 16px,
        #3D6DE814 32px
      );
    }
  }

  td, th {
    padding: 0 $tableGutter $tableGutter 0;

    &:nth-child(3), &:nth-child(4) {
      position: sticky;
      z-index: 1;
      background-color: $feta;
    }

    &:nth-child(-n + 5) {
      @media screen and (min-width: $tablet) {
        position: sticky;
        z-index: 1;
        background-color: $feta;
      }
    }

    &:first-child {
      @media screen and (min-width: $tablet) {
        left: 0;
      }
    }

    &:nth-child(2) {
      @media screen and (min-width: $tablet) {
        left: 64px;
      }
    }

    &:nth-child(3) {
      left: -56px;

      @media screen and (min-width: $tablet) {
        left: 152px;
      }
    }

    &:nth-child(4) {
      left: 8px;

      @media screen and (min-width: $tablet) {
        left: 216px;
      }
    }

    &:nth-child(5) {
      @media screen and (min-width: $tablet) {
        left: 584px;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__link {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
