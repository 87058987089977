@import "../../../styles/var.scss";

.stat-tile {
  min-height: 88px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid $eel;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;
  color: $truffle;

  @media screen and (min-width: $tablet) {
    min-height: 144px;
  }

  &--small {
    @media screen and (min-width: $tablet) {
      min-height: 130px;
    }
  }

  &--success {
    border-color: $kiwi;
    background-color: $courgette;
  }

  &--warning {
    border: 2px solid $salmon;
    background-color: $bubblegum;
  }

  &--disabled {
    border-color: $haddock;
  }

  &__heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 18.9px;

    @media screen and (min-width: $tablet) {
      font-size: 15px;
      line-height: 20.25px;
    }

    &--warning {
      color: $fmo;
    }

    &--disabled {
      color: $thali02;
    }
  }

  &__subheading {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.4px;
    letter-spacing: 0.02em;
    opacity: 0.8;
  
    @media screen and (min-width: $tablet) {
      font-size: 13px;
      line-height: 18.2px;
      letter-spacing: 0.01em;
    }
  }

  &__value {
    font-size: 22px;
    line-height: 28.6px;
    letter-spacing: 0.01em;
    font-weight: 800;
    color: $soySauce;

    @media screen and (min-width: $tablet) {
      font-size: 26px;
      line-height: 32.5px;
    }

    &--success {
      color: $artichoke;
    }

    &--warning {
      color: $fmo;
    }

    &--disabled {
      color: $thali01;
    }
  }

  @media screen and (min-width: $tablet) {
    letter-spacing: 0.01em;
  }
}