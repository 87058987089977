@import "../../styles/var";
@import "../../index.scss";

.my-jobs-sorter {
  &__title {
    display: none;

    @media screen and (min-width: $tablet) {
      display: block;
      font-weight: 600;
      font-size: 15px;
      line-height: 20.25px;
      letter-spacing: 1%;
      color: $truffle;
    }
  }

  &__button-wrapper {
    display: flex;
    gap: 8px;
  }

  &__button {
    height: 40px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border: 1px solid $eel;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 20.25px;
    color: $thali02;

    &--disabled {
      cursor: initial;
    }

    &--active {
      background-color: $eel;
      color: $truffle;
    }
  }
}

.agent-jobs {
  position: absolute;
  background-color: $feta;
  top: 128px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  @media screen and (min-width: $tablet) {
    padding: 24px 16px 32px 0;
    top: 80px;
    left: 110px;
  }

  &__title {
    margin: 0 0 0 16px;
    padding: 24px 0 16px 4px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }

  &__card-wrapper {
    padding: 0 16px 16px;
    display: grid;
    gap: 16px;

    @media screen and (min-width: $tablet) {
      padding: 0;
    }

    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1350px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }
}