@import "../../styles/var";

.progress-bar {
  display: flex;
  align-items: center;
  gap: 16px;

  &__progress-number {
    margin-bottom: 4px;
    font-weight: 800;
    font-size: 36px;
    line-height: 50.4px;

    @media screen and (min-width: $tablet) {
      margin-bottom: 8px;
      font-size: 50px;
      line-height: 60px;
      letter-spacing: -0.01em;
    }
  }

  &__bar {
    background-color: $eel;
    height: 8px;
    border-radius: 4px;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    transition: 0.25s;
  }

  &__bar-inner {
    position: absolute;
  }

  &__text {
    min-width: 32px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0.05em;
    opacity: 0.8;
    color: #464141CC;
  }
}