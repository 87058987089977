@import "../../../styles/var";

.new-prospect {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $feta;
  overflow: auto;

  @media screen and (min-width: $tablet) {
    left: 110px;
  }

  &__inner {
    margin: 24px 0 32px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: $sourCream;

    @media screen and (min-width: $tablet) {
      margin: 24px 16px 32px 0;
      padding: 32px;
      border: 1px solid $eel;
      border-radius: 8px;
    }
  }
}